import styled from 'styled-components';

const PopupWrapper = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const CloseButton = styled.span`
  top: 10px;
  right: 15px;
  cursor: pointer;
  position: absolute;
`;

const Popup = ({ open, onClose, title = '', content }: any) => {
  return (
    <PopupWrapper open={open}>
      <PopupContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h2>{title}</h2>
        <p>{content}</p>
      </PopupContent>
    </PopupWrapper>
  );
};

export default Popup;