import { Authenticator, useAuthenticator, useTheme, View, Image, Heading } from '@aws-amplify/ui-react';
import { ThemeProvider } from 'styled-components';
import theme from './shared/theme';
import Router from './shared/Router';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Trackit Logo"
          src="https://trackit.io/wp-content/uploads/2019/08/Logo1-colour-CMYK-small.png"
        />
      </View>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
          textAlign="center"
        >
          MSP Portal login
        </Heading>
      );
    },
  }
};

const fields = {
  signIn: {
    username: {
      placeholder: 'Email',
      isRequired: true,
      labelHidden: true,
    },
    password: {
      placeholder: 'Password',
      isRequired: true,
      labelHidden: true,
    },
  }
};

const App = () => {
  const { user, authStatus } = useAuthenticator((context) => [context.authStatus, context.user]);

  return authStatus === 'authenticated' && user ? (
    <ThemeProvider theme={theme}>
      <Router user={user} />
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <Authenticator
        loginMechanisms={['email']}
        hideSignUp={true}
        components={components}
        formFields={fields}
      />
    </ThemeProvider>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <Authenticator.Provider>
    <App />
  </Authenticator.Provider>
);
