import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './shared/config';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  aws_cognito_region: config.Region,
  aws_user_pools_id: config.UserPool.Id,
  aws_user_pools_web_client_id: config.UserPool.Client.Id,
  aws_appsync_region: config.Region,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint:config.AppSyncUrl.Id,
  Auth: {
    mandatorySignIn: true,
    region: config.Region,
    userPoolId: config.UserPool.Id,
    userPoolWebClientId: config.UserPool.Client.Id,
    identityPoolId: config.IdentityPool.Id,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
