import { Auth } from '@aws-amplify/auth';
import { graphqlOperation, API } from 'aws-amplify';
import {
  ClientData,
  ServiceDocument,
  TimeSeries,
  TimeSeriesInstanceType,
  TimeSeriesUserUsage,
  RefreshMetrics
} from '../shared/client.interfaces';

interface ReturnData {
  data?: {
    'getClient': ClientData[],
    'getRunningInstances': TimeSeries[],
    'getInstanceTypeUsage' : TimeSeriesInstanceType[],
    'getUserUsage': TimeSeriesUserUsage[],
    'refreshMetrics': RefreshMetrics,
  }
}

export class GraphQlApi {

  private getClientQuerry = `getClient(clientNames: $clientNames) {
    name
    status
    services {
      name
      status
      lastUpdate
      ... on AlarmService {
        __typename
        alarmStatus {
          name
          status
          lastUpdate
          period
        }
      }
      ...on MetricService {
        __typename
        metricStatus {
          name
          status
          lastUpdate
          logs
        }
        alarmStatus {
          name
          status
          lastUpdate
          alarmStatus {
            name
            status
            lastUpdate
            period
          }
        }
      }
    }
  }`;

  async getClient(clientNames: string[]) {
    const token = await Auth.currentSession().then(result => result.getIdToken().getJwtToken());
    const names = clientNames.map((clientName: string) => `"${clientName}"`);

    const operation = graphqlOperation(`
    query theQuery($clientNames: [String] = [${names}])
    {
      ${this.getClientQuerry}
    }`, {}, token);

    const data = await API.graphql(operation) as ReturnData;
    if(!data.data){
      throw new Error("Data not found.");
    }

    const clients: ClientData[] = [];
    data.data.getClient!.forEach(client => {
      //formating services, to threat nimble, may change in future
      client.services = this.formatServices(client.services)
      clients.push(client);
    })

    return clients;
  }

  async refreshMetrics(clientName: string){
    const name = `"${clientName}"`;
    const token = await Auth.currentSession().then(result => result.getIdToken().getJwtToken());
    const operation = graphqlOperation(`
    query theQuery($clientName: String = ${name})
    {
      refreshMetrics(clientName: $clientName){
        statusCode
        message
      }
    }`, {}, token);

    const data = await API.graphql(operation) as ReturnData;
    if(!data.data) throw new Error("Data not found.");

    return data.data;
  }

  async getRunningInstances(clientName: string, days: number): Promise<TimeSeries[]> {
    const name = `"${clientName}"`;
    const token = await Auth.currentSession().then((result) => result.getIdToken().getJwtToken());
    const operation = graphqlOperation(`
    query theQuery($clientName: String = ${name}, $days: Int=${days})
    {
      getRunningInstances(clientName: $clientName, days: $days) {
        day
        runningInstancesByDay {
          date
          value
          lastUpdate
        }
        averageInstances
        missingData
      }
    }`, {}, token);

    const data = await API.graphql(operation) as ReturnData;
    if (!data.data) throw new Error("Data not found.");

    return data.data.getRunningInstances;
  }

  async getInstanceTypeUsage(clientName: string) {
    const name = `"${clientName}"`
    const token = await Auth.currentSession().then(result => result.getIdToken().getJwtToken());
    const operation = graphqlOperation(`
    query theQuery($clientName: String = ${name})
    {
      getInstanceTypeUsage(clientName: $clientName){
        value
        timestamp
        instancetype
      }
    }`, {}, token);

    const data = await API.graphql(operation) as ReturnData;
    if (!data.data) throw new Error("Data not found.!");

    return data.data.getInstanceTypeUsage;
    }

    async getUserUsage(clientName: string, days: number){
      const name = `"${clientName}"`
      const token = await Auth.currentSession().then(result => result.getIdToken().getJwtToken());
      const operation = graphqlOperation(`
      query theQuery($clientName: String = ${name}, $days: Int=${days})
      {
        getUserUsage(clientName: $clientName, days: $days){
          value
          timestamp
          username
        }
      }`, {}, token);

      const data = await API.graphql(operation) as ReturnData;
      if(!data.data){
        throw new Error("data not founded!");
      }

      return data.data.getUserUsage;
    }

  private formatServices(services: any[]): ServiceDocument[]{
    const formatedServices: ServiceDocument[] = []

    for (const service of services) {
      if(service.__typename === "AlarmService") {
        formatedServices.push(service)
      }

      if(service.__typename === "MetricService") {
        if(service.metricStatus) {
          formatedServices.push(...service.metricStatus)
        }

        if(service.alarmStatus){
          formatedServices.push(...service.alarmStatus)
        }
      }
    }

    return formatedServices;
  }
}