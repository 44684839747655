/* eslint-disable no-await-in-loop */
import { Auth } from '@aws-amplify/auth';
import { GraphQlApi } from './graphql'
import { ClientData } from '../shared/client.interfaces';

export const getClientDataGraphQl = async (): Promise<ClientData[]> => {

  const graphQlApi = new GraphQlApi();

  interface AuthDecodedToken {
    "cognito:groups": string[]
    email: string
  }

  const decodedToken = await Auth.currentSession().then(result => result.getIdToken().decodePayload()) as AuthDecodedToken

  // no group assigned
  if(!(decodedToken["cognito:groups"])){
    return [];
  }

  const userGroups = decodedToken["cognito:groups"];
  const clients: ClientData[] = await graphQlApi.getClient(userGroups);

  return clients;
}