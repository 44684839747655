import 'chart.js/auto';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  useState } from 'react';
import {
  ClientContainer,
  ItemContainer,
  ItemDetails,
  ItemHead,
  ItemStatus,
  ItemTitle,
  LastUpdateBox,
  LogsBox,
  LogsHead,
  ServiceHead,
  ServicesBox,
  ServiceStatusIndicator,
  ServiceTitle,
  StatusIndicator,
} from './ItemBoxElement';
import { ClientBoxParameters, Service, ServiceDocument, Status, TimeSeries, TimeSeriesInstanceType, TimeSeriesUserUsage, Typename} from '../../shared/client.interfaces';
import { DateTime } from "luxon";
import { Chart, ChartOptions, ChartConfiguration } from 'chart.js';

const getDateFormat = (date: string): string => DateTime.fromISO(date).toFormat("HH:mm, MM/dd/yyyy");
const getLastCheckFormat = (seconds: number): string => DateTime.now().plus({seconds: -1 * seconds}).toFormat("HH:mm, MM/dd/yyyy")
const getStatusColor = (status: string) => {
  switch (status) {
    case Status.OK:
      return '#52F2A5';
    case Status.DOWN:
      return '#E63946';
    case Status.PROBLEM:
      return '#E5EA00';
    default:
      return '#E63946';
  }
};

const getLastServiceLastCheck = (services: ServiceDocument[]): number =>{
  let lastcheck = 0;
  let maxPeriods: number[] = [];
  services.forEach((element) => {
    if (element.__typename === Typename.ALARM_SERVICE || element.__typename === Typename.METRIC_SERVICE) {
      const periodArr = element.alarmStatus?.reduce((accumulator: number[], current) => {
        // Use optional chaining to check if element.alarmStatus is defined before calling reduce
        if (current.hasOwnProperty('period')) {
          accumulator.push(current.period || 0);
        }
        return accumulator;
      }, []) || [];
      // Use a default value of [] if periodArr is undefined to avoid errors with Math.min()
      if (periodArr.length > 0) { // Only get maxPeriod if there are elements in periodArr
        const maxPeriod = Math.max(...periodArr);
        if (maxPeriod > lastcheck) lastcheck = maxPeriod;
        maxPeriods.push(lastcheck);
        lastcheck = Math.max(...maxPeriods);
      }
    }
  })
  return lastcheck;
};

const getLastServiceLastUpdate = (services: Service[]): Service | null => {
  const sortedServices = services.filter(service => service.lastUpdate != null).sort((n1, n2) => {
    const date1 = new Date(n1?.lastUpdate || '');
    const date2 = new Date(n2?.lastUpdate || '');
    return date2.getTime() - date1.getTime();
  });
  return sortedServices[0] || null;
};

export const ClientBox = ({ client, selectClient }: ClientBoxParameters) => {
  const lastcheck = getLastServiceLastCheck(client.services);
  const lastUpdateService = getLastServiceLastUpdate(client.services);

  return (
    <ClientContainer onClick={() => selectClient(client)}>
      <ItemStatus>
        <StatusIndicator color={getStatusColor(client.status)}/>
        <ItemTitle>{client.name}</ItemTitle>
      </ItemStatus>
    <ItemDetails>
    {lastcheck ? (
      <LastUpdateBox>
        <h5>Last Check</h5>
        <p>{getLastCheckFormat(lastcheck)}</p>
        </LastUpdateBox>
      ) : (
        <LastUpdateBox>
          <h5>Last Update</h5>
          <p>{getDateFormat(lastUpdateService?.lastUpdate||'')}</p>
        </LastUpdateBox>
      )}
      </ItemDetails>
    </ClientContainer>
  );
};

export const ServicesList = ({ services }: { services: Service[] | undefined }) => (
    <ServicesBox>
        {services && services.map((item) => (
            <div key={item.name}>
                <ServiceHead>
                    <ItemStatus color={'50%'}>
                        <ServiceStatusIndicator color={getStatusColor(item.status)}/>
                        <ServiceTitle>{item.name}</ServiceTitle>
                    </ItemStatus>
                    <ItemDetails>
                    {item.lastUpdate &&
                            <LastUpdateBox>
                                <h5>Last Update</h5>
                                <p>{getDateFormat(item.lastUpdate)}</p>
                            </LastUpdateBox>}
                        {item.period &&
                            <LastUpdateBox>
                                <h5>Last Check</h5>
                                <p>{getLastCheckFormat(item.period)}</p>
                            </LastUpdateBox>}
                    </ItemDetails>
                </ServiceHead>
            </div>
        ))
        }
    </ServicesBox>
);

export async function createLineChart(runningInstances: TimeSeries[]) {
  if (runningInstances.length <= 0) return;

  let timestamps: any[] = [];
  let averageValues: any[] = [];
  let fixedValues: any[] = [];
  runningInstances.forEach((instance, index) => {
    if (instance.missingData) return;
    timestamps.push(new Date(instance.day).toLocaleString());
    if (index >= runningInstances.length - 1) {
      fixedValues.push(instance.averageInstances);
    } else {
      fixedValues.push(null);
      averageValues.push(instance.averageInstances);
    }
  });

  const chartData = {
    labels: timestamps,
    datasets: [
      {
        label: 'Running instances (average)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        data: averageValues,
      },
      {
        label: 'Running instances (fixed)',
        backgroundColor: 'rgba(124, 252, 0, 0.2)',
        borderColor: 'rgba(124, 252, 0, 1)',
        borderWidth: 1,
        data: fixedValues,
        spanGaps: false,
      }
    ],
  };

  const chartOptions: ChartConfiguration['options'] = {
    scales: {
      y: {
        ticks: {
          fontSize: 10,
        },
      },
      x: {
        ticks: {
          fontSize: 10,
        },
        barThickness: 0.2,
        categoryPercentage: 0.8,
        barPercentage: 0.9,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 5,
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Running instances number per date',
      },
    },
  } as ChartOptions<'bar'>;

  const canvas = document.getElementById('myChart') as HTMLCanvasElement;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  // Get the existing chart instance if it exists
  const existingChart = Chart.getChart(canvas);

  if (existingChart) {
    // If a chart already exists, destroy it before creating a new one
    existingChart.destroy();
  }

  new Chart(ctx, {
    type: 'line',
    data: chartData,
    options: chartOptions,
  });
}


export async function createBarChart(instancetypehours:TimeSeriesInstanceType[]) {
  if (instancetypehours.length <= 0) return;

  const instancesAmount = instancetypehours.map((instance) => instance.value);
  const instancesTypes = instancetypehours.map((instance) => instance.instancetype);
  const chartData = {
    labels: instancesTypes,
    datasets: [
      {
        label: `Running instances`,
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        data: instancesAmount,
      },
    ],
  };

  const chartOptions: ChartConfiguration['options'] = {
    scales: {
      y: {
        ticks: {
          fontSize: 10,
          stepSize: 1,
        },
      },
      x: {
        ticks: {
          fontSize: 10,
        },
        barThickness: 20,
        categoryPercentage: 0.8,
        barPercentage: 0.9,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 5,
      },
    },
    plugins: {
      title: {
        display: true,
        text: `Running instances number per type, ${new Date(instancetypehours[0].timestamp).toLocaleString()}`,
      },
    },
  } as ChartOptions<'bar'>;

  const canvas = document.getElementById('myChart1') as HTMLCanvasElement;
  if (canvas) {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const existingChart = Chart.getChart(canvas);

    if (existingChart) {
      existingChart.destroy();
    }
    new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: chartOptions,
    });
  }
}
export async function createPieChart(userusage: TimeSeriesUserUsage[]) {
  if (userusage.length > 0) {
    const hours = userusage.map((user) => user.value);
    const usernames = Array.from(new Set(userusage.map((user) => {
      const parts = user.username.split('-');
      return parts.pop();
    })));

    // Generate colors dynamically based on the number of unique usernames
    const colors = Array.from({ length: usernames.length }, (_, i) => {
      const hue = (360 / usernames.length) * i;
      return `hsla(${hue}, 60%, 70%, 0.8)`;
    });

    // Assign a different color to each label
    const chartData = {
      labels: usernames,
      datasets: [
        {
          label: 'Instance Type per Hours Used',
          backgroundColor: usernames.map((_, i) => colors[i % colors.length]),
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          data: hours,
        },
      ],
    };

    const chartOptions: ChartConfiguration['options'] = {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 5,
        },
      },
    } as unknown as ChartOptions<'bar'>;

    const canvas = document.getElementById('myChart2') as HTMLCanvasElement;
    if (canvas) {
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      const existingChart = Chart.getChart(canvas);

      if (existingChart) {
        existingChart.destroy();
      }
      new Chart(ctx, {
        type: 'pie',
        data: chartData,
        options: chartOptions,
      });
    }
  }
}

export const ItemBox = (item: Service) => {
  const [shown, setShown] = useState(false);
  return (
    <ItemContainer onClick={() => setShown(!shown)}>
        <ItemHead>
            <ItemStatus>
                <StatusIndicator color={getStatusColor(item.status)}/>
                <ItemTitle>{item.name}</ItemTitle>
            </ItemStatus>
            <LastUpdateBox onClick={() => setShown(!shown)}>
            {item.lastUpdate
                    && <>
                    <h5>Last Update</h5>
                    <p>{getDateFormat(item.lastUpdate)}</p></>
                }
                {item.period
                    && <>
                    <h5>Last Check</h5>
                    <p>{getLastCheckFormat(item.period)}</p></>
                }
                {(item.alarmStatus !== undefined || item.logs !== undefined)
                    && <FontAwesomeIcon icon={!shown ? faArrowDown : faArrowUp} />}
            </LastUpdateBox>
        </ItemHead>
        {shown && <ServicesList key={item.name} services={item.alarmStatus}/>}
        {shown && item.logs
            && <LogsHead>
                <h5 >Logs</h5>
                  <LogsBox key={`${item.logs}-logs`}>
                      {item.logs.map((log, index) => (
                    <p key={index}>{log}</p>
                      ))}
                  </LogsBox>
            </LogsHead>
            }
    </ItemContainer>
  );
};
