import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { faRefresh, faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createBarChart, createLineChart, createPieChart, ItemBox } from '../components/ItemBox';
import { ClientData, Service} from '../shared/client.interfaces';
import { CenterContainer, ChartContainer, HomeContainer, HomeTitle } from './PageElements';
import Button from '../components/common/Button';
import { getRunningInstanceDataGraphQl } from '../data/runninginstance';
import Loading from '../components/common/Loading';
import { getInstanceTypeUsageDataGraphQl } from '../data/instancetypehours';
import { refreshPage } from '../data/refresh';
import { getUserUsageDataGraphQl } from '../data/userusage';
import styled from 'styled-components';
import Popup from '../components/Popup';

const BtnWrapper = styled.div`
  margin-left: auto;
  margin-right: 2rem;
  display: flex;
`;

const Btn = styled(Button)`
  span {
    font-size: .75rem;
  }
  @media screen and (max-width: 480px) {
    span {
      display: none;
    }
    &:after {
      content: "";
    }
    margin-left: auto;
  }
  line-height: 0px;
`;

const RefreshButton = ({ clientName, setOpenPopup } : { clientName: string, setOpenPopup: any }) => {
  return (
    <BtnWrapper>
      <Btn onClick={async () => {
        await refreshPage(clientName);
        window.location.reload();
      }}>
        <FontAwesomeIcon icon={faRefresh} />
        <span>Refresh</span>
        </Btn>
      <FontAwesomeIcon icon={faInfoCircle}
        size={'lg'}
        style={{
          color: '#6c757d',
          cursor: 'pointer',
        }}
        onClick={() => { setOpenPopup(true); document.body.style.overflow = 'hidden' }}
      />
    </BtnWrapper>
  );
}

const Client = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [barChartData, setBarChartData] = useState<any>([]);
  const [lineChartData, setLineChartData] = useState<any>([]);
  const [pieChartData, setPieChartData] = useState<any>([]);
  const [barChartLoading, setBarChartLoading] = useState<boolean>(true);
  const [lineChartLoading, setLineChartLoading] = useState<boolean>(true);
  const [pieChartLoading, setPieChartLoading] = useState<boolean>(true);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const history = useHistory();
  const data = history.location.state as ClientData;

  useEffect(() => {
    (async () => {
      try {
        const response = await getRunningInstanceDataGraphQl(data.name);
        if (response)
          setLineChartData(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLineChartLoading(false);
      }
    })();
  }, [data.name]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getInstanceTypeUsageDataGraphQl(data.name);
        if (response) {
          setBarChartData(response);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setBarChartLoading(false);
      }
    })();
  }, [data.name]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getUserUsageDataGraphQl(data.name);
        if (response) {
          setPieChartData(response);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setPieChartLoading(false);
      }
    })();
  }, [data.name]);

  const singleChart = barChartData.length === 0;

  useEffect(() => {
    setServices(data.services);
    if (!barChartLoading && !lineChartLoading && !pieChartLoading) {
      if (singleChart && lineChartData) {
        createLineChart(lineChartData);
      } else if (!singleChart && barChartData && lineChartData && pieChartData) {
        createBarChart(barChartData);
        createLineChart(lineChartData);
        createPieChart(pieChartData);
      }
    }
  }, [barChartLoading, lineChartLoading, pieChartLoading, singleChart, data.name, barChartData, lineChartData, pieChartData, data.services]);

  return (
    <HomeContainer>
      <HomeTitle>
        {openPopup ?
          <Popup
            open={openPopup}
            onClose={() => { setOpenPopup(false); document.body.style.overflow = 'auto' }}
            title={'Information'}
            content={'The refresh process may take a few seconds to complete. Manually reloading the page may be required.'}
          /> : null}
        <Button variant={'link'} onClick={() => history.push('/')}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <h2>{data.name || 'Clients'}</h2>
        <RefreshButton
          clientName={data.name}
          setOpenPopup={setOpenPopup}
        />
      </HomeTitle>
      <CenterContainer>
        {services && services.map((item: Service) => (
          <ItemBox {...item} key={item.name}/>
        ))}
      </CenterContainer>
      <ChartContainer singleChart={singleChart}>
        {lineChartLoading ? (
          <Loading size={'normal'}  />
        ) : (
          <canvas id="myChart" />
        )}
        {pieChartLoading ? (
          <Loading size={'normal'}  />
        ) : (
          !singleChart && <canvas id="myChart2" />
        )}
        {barChartLoading ? (
          <Loading size={'normal'}  />
        ) : (
          !singleChart && <canvas id="myChart1" />
        )}

      </ChartContainer>
    </HomeContainer>
  );
};

export default Client;