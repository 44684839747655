import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import Home from '../pages/Home';
import Client from '../pages/Client';
import Header from '../components/Header';
import { AmplifyUser } from '@aws-amplify/ui';

const Body = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: calc(100vh - 80px);
`;

const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface RouterProps {
  user: AmplifyUser;
}

const Router = (props: RouterProps) => (
      <>
      <BrowserRouter>
        <Header user={props.user} />
          <Body>
            <BodyWrapper>
                <Switch>
                  <Route exact path="/:id" component={Client}/>
                  <Route exact path='/' component={Home}/>
                </Switch>
                  </BodyWrapper>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
              </Body>
      </BrowserRouter>
      </>
);

export default Router;
