const config = {
  Region: process.env.REACT_APP_REGION,
  UserPool: {
    Id: process.env.REACT_APP_USER_POOL_ID,
    Client: {
      Id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    },
  },
  IdentityPool: {
    Id: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  AppSyncUrl: {
    Id: process.env.REACT_APP_APPSYNC_URL,
  }
};

export default config;
