import styled from 'styled-components';

export const HomeContainer = styled.div`
  background: #f7f7f8;
  height: 100%;
  width: 100%;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  background-color: #f7f7f8;
`;
export const ChartContainer = styled.div<{ singleChart: boolean }>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: ${({ singleChart }) => (singleChart ? 'center' : 'space-evenly')};
  canvas {
    margin: 10px;
    max-width: 450px;
    max-height: 300px;
  }
  background-color: #f7f7f8;
`;

export const HomeTitle = styled.div`
  display: flex;
  align-items: center;
  color: black;
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 72px;
  padding-top: 25px;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 35px;
  }
  @media screen and (max-width: 480px) {
    font-size: 25px;
  }
  @media screen and (min-width: 1200px) {
    padding-left: 50px;
  }
`;

export const BackIconTitle = styled.div`
    margin-right: 1%;
    cursor: pointer;
`;
