import styled from 'styled-components';

export const ClientContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
  align-items: stretch;
  text-align: center;
  border-radius: 20px 20px 20px 20px;
  background: white;
  padding: 20px;
  box-shadow: 6px 6px 2px 1px rgba(0, 0, 0, .2);
  margin-bottom: 30px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

export const ItemContainer = styled.div`
  width: 70%;
  align-items: stretch;
  text-align: center;
  border-radius: 20px 20px 20px 20px;
  background: white;
  padding: 20px;
  box-shadow: 6px 6px 2px 1px rgba(0, 0, 0, .2);
  margin-bottom: 30px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    width: 90%;
    flex-direction: row;
  }
`;

export const ItemHead = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    
  }
`;

export const StatusIndicator = styled.span`
  height: 42px;
  min-width: 42px;
  background-color: ${({ color }) => (color || 'red')};
  border-radius: 50%;
  display: inline-block;
`;

// @ts-ignore
export const ItemStatus = styled.div`
  width: ${({ color }) => (color || '25%')};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  @media screen and (max-width: 480px) {
    
  }
`;

export const ItemDetails = styled.div`
  width: ${({ color }) => (color || '25%')};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

export const ItemTitle = styled.h4`
  text-align: center;
  color: black;
  margin-left: 30px;
  @media screen and (max-width: 768px) {
    
  }
  @media screen and (max-width: 480px) {
    
  }
`;

export const LastUpdateBox = styled.div`
  @media screen and (max-width: 768px) {
    
  }
  @media screen and (max-width: 480px) {
    
  }
`;

export const ServicesBox = styled.div`
    width: 100%;
    border-left: black solid 3px;
    justify-content: start;
    margin-left: 30px;
`;

export const ServiceHead = styled.div`
    display: flex;
    justify-content: start;
    margin: 25px 0 0 20px;
`;

export const ServiceStatusIndicator = styled.span`
  height: 25px;
  min-width: 25px;
  background-color: ${({ color }) => (color || 'red')};
  border-radius: 50%;
  display: inline-block;
`;

export const ServiceTitle = styled.h5`
  text-align: left;
  color: black;
  margin-left: 30px;

  @media screen and (max-width: 768px) {
    
  }
  @media screen and (max-width: 480px) {
    
  }
`;

export const LogsHead = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  margin: 25px 0 0 20px;
  border-radius: 5px;
  text-align: left;
`;

export const LogsBox = styled.div`
  background: rgba(196, 196, 196, 0.17);
  margin: 20px;
  color: black;
  left: 0px;
  width: 80%;
  padding: 10px;
  @media screen and (max-width: 768px) {
    
  }
  @media screen and (max-width: 480px) {
    
  }
`;
