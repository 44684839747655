/* eslint-disable no-await-in-loop */
import { GraphQlApi } from './graphql'
import { TimeSeries } from '../shared/client.interfaces';

export const getRunningInstanceDataGraphQl = async (clientName:string): Promise<TimeSeries[]> => {
  try {
    const graphQlApi = new GraphQlApi();
    const days = 10;
    const runninginstances: TimeSeries[] = await graphQlApi.getRunningInstances(clientName, days);
    return runninginstances;
  } catch(e) {
    throw(e);
  }
}
