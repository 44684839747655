import styled from 'styled-components';

interface LoadingStyleProps {
  size: {
    dimensions: string;
    borderSize: string;
  }
}

interface Props {
  size: 'small' | 'normal' | 'large'
}

const LoadingStyle = styled.div<LoadingStyleProps>`
  border: ${(props) => props.size.borderSize} solid #f3f3f3;
  border-top: ${(props) => props.size.borderSize} solid #E63946;
  border-radius: 50%;
  width: ${(props) => props.size.dimensions};
  height: ${(props) => props.size.dimensions};
  animation: spin 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loading = ({ size }: Props) => {
  const sizes = {
    small: {
      dimensions: '20px',
      borderSize: '2px',
    },
    normal: {
      dimensions: '28px',
      borderSize: '3px',
    },
    large: {
      dimensions: '36px',
      borderSize: '3px',
    },
  };

  return (<LoadingStyle size={sizes[size as keyof typeof sizes]}/>);
};

export default Loading;
