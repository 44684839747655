import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Button from '../common/Button';
import { AmplifyUser } from '@aws-amplify/ui';

const Logo = require('./trackit-white-logo.png');

const HeaderContainer = styled.header`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E63946; // 230, 57, 70
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: auto;
  flex: 1;
  height: 60px;
`;

const UserText = styled.span`
  color: ${(props) => props.theme.color.primary};
  margin: 0 ${(props) => props.theme.margins.base};
  font-family: 'Montserrat', sans-serif;
  color: white;
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TrackitLogo = styled.a`
  margin: ${(props) => props.theme.margins.largest};
  cursor: pointer;
  img {
    height: 40px;
    @media screen and (max-width: 768px) {
      height: 32px;
    }
    @media screen and (max-width: 480px) {
      height: 24px;
    }
  }
`;

const Btn = styled(Button)`
  span {
    font-size: .75rem;
  }
  @media screen and (max-width: 480px) {
    span {
         display: none;
    }
    &:after {
        content: "";
    }
  }
`;

interface HeaderProps {
  user: AmplifyUser;
}

const Header = (props: HeaderProps) => {
  const history = useHistory();

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <TrackitLogo onClick={() => history.push('/')}>
          <img src={Logo} alt="TrackItLogo" />
        </TrackitLogo>
        <Row>
          <UserText>Hello, {props?.user?.attributes?.email!}</UserText>
          <Btn
            variant="primary"
            onClick={() => Auth.signOut()}
          >
            <FontAwesomeIcon icon={faSignOutAlt}/>
            <span>
              Sign out
            </span>
          </Btn>
        </Row>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default Header;
