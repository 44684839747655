export interface ClientData {
  services: ServiceDocument[];
  name: string;
  status: string;
}
export enum Typename {
  'ALARM_SERVICE' = 'AlarmService',
  'METRIC_SERVICE' = 'MetricService',
}

export interface Service {
  name: string;
  lastUpdate: string;
  status: string;
  alarmStatus?: Service[];
  period?: number;
  logs?: string[];
}
export interface InstanceTypeHours {
  instanceType: string;
  hours: number | string;
}

interface Instance {
  date: string;
  value: string;
  lastUpdate: string;
}

export interface TimeSeries {
  day: string;
  runningInstancesByDay: Instance[];
  averageInstances: number;
  missingData: boolean;
}

export interface TimeSeriesInstanceType{
  value: string,
  timestamp:string,
  instancetype: string,
}

export interface TimeSeriesUserUsage{
  value: string,
  timestamp:string,
  username: string,
}

export interface RefreshMetrics {
  statusCode: number;
  message: string;
}

export interface ServiceDocument extends Service {
  __typename: Typename;
}


export interface ClientConfig {
  assumeRole: string;
  clientName: string;
  stsRegion: string;
  services: any[];
}

export interface ClientBoxParameters {
  client: ClientData;
  selectClient: (value: ClientData) => void;
}

export interface RunningInstanceBoxParameters {
  runninginstance : TimeSeries[];
}

export enum Status {
  OK = 'ok',
  PROBLEM = 'problem',
  DOWN = 'down',
}