import { GraphQlApi } from './graphql'

export const refreshPage = async (clientName:string) => {
  try {
    const graphQlApi = new GraphQlApi();
    await graphQlApi.refreshMetrics(clientName);

  } catch(e) {
    throw(e);
  }
}
