const theme = {
  // Font Sizes
  fontSize: {
    smaller: '10px',
    small: '12px',
    base: '14px',
    large: '16px',
    larger: '20px',
    huge: '24px',
    titlePrimary: '26px',
    titleSecondary: '22px',
    logoFontSize: '29px',
  },
  // Font weight
  fontWeight: {
    lighter: '200',
    light: '400',
    base: '500',
    bold: '600',
    bolder: '800',
  },
  // Colors
  color: {
    brand: '#d63d35',
    textPrimary: '#49426c',
    textSecondary: '#b0b0cb',
    textTertiary: '#000000',
    primary: 'black',
    background1: '#d63d35',
    background2: '#e7e7f1',
    background3: '#a3a5d3',
    selectedBackground: 'rgba(79, 85, 227, 0.3)',
    selectedBackgroundHover: 'rgba(79, 85, 227, 0.7)',
    border: '#cfcfe1',
    borderLight: '#ebecef',
    healthy: '#5bcf76',
    warning: '#f7e463',
    danger: '#d63d35',
  },
  // Margins and Paddings
  margins: {
    smallest: '2px',
    smaller: '4px',
    smallerAlt: '5px',
    small: '8px',
    medium: '10px',
    base: '16px',
    large: '20px',
    larger: '24px',
    largest: '32px',
    big: '40px',
  },
  // Icon Size
  iconSize: {
    light: '10px',
    small: '20px',
    medium: '25px',
    large: '30px',
    larger: '35px',
    big: '40px',
  },
  // Icon Color
  iconColor: {
    black: '#000000',
    disabledGrey: '#cccccc',
    disabledGrey2: '#545454',
    red: '#930000',
    green: '#009323',
    darkGreen: '#008000',
    white: '#ffffff',
  },
  screenBreakpoints: {
    mobile: '(max-width: 767px)',
    tabletPortrait: '(min-width: 768px) and (max-width: 991px)',
    mobileAndTabletPortrait: '(max-width: 991px)',
    desktopAndTabletLandscape: '(min-width: 992px)',
  },
};

export default theme;
