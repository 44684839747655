import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { ClientBox } from '../components/ItemBox';
import { ClientData } from '../shared/client.interfaces';
import { getClientDataGraphQl} from '../data/client';
import { CenterContainer, HomeContainer, HomeTitle } from './PageElements';
import Loading from '../components/common/Loading';

const Home = () => {
  const [clients, setClients] = useState<any>();
  const [loading, setLoading] = useState<Boolean>(true);
  const [groups, setGroups] = useState<string[]>();
  const history = useHistory();

  function chooseClient(value: ClientData) {
    const route = value.name.toLowerCase()
    history.push(`/${route}`, value);
  }


  const groupSetter = async () => {
    try {
      const array = await Auth.currentAuthenticatedUser();
      return array.signInUserSession.idToken.payload['cognito:groups'].map((element: string) => element.toLowerCase());
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      if(!clients){
        setClients(await getClientDataGraphQl())
      }
      setLoading(false);
      setGroups(await groupSetter());
    })();
  });
  

  if (loading) {
    return (
        <Loading size={'large'} />
    );
  }
  return (
      <HomeContainer>
        <HomeTitle>{'Clients'}</HomeTitle>
        <CenterContainer>
          {clients && clients.map((item: any) => {
            const route = item.name.toLowerCase();
            if (groups && (groups.includes(route) || groups.includes('trackit'))) {
              return (
                
                  <ClientBox client={item} selectClient={chooseClient} key={route}/>
                  
               
              );
            }
            return null;
          })
          
          }
        </CenterContainer>
      </HomeContainer>
  );
};

export default Home;
