import React from 'react';
import styled, { css } from 'styled-components';

type ButtonProps = {
  variant?: string
  children: React.ReactNode
  onClick?: () => void
  type?: string
  disabled?: boolean
  className?: string
};

const ButtonWrapper = styled.button<ButtonProps>`
  background-color: white;
  border: none;
  font-size: ${(props) => props.theme.fontSize.base};
  padding: ${(props) => props.theme.margins.small}
    ${(props) => props.theme.margins.base};
  border-radius: 20px;
  cursor: pointer;
  transition: all ease-in 200ms;
  margin: 0px ${(props) => props.theme.margins.smaller};

  // Icon
  svg {
    margin: 0px ${(props) => props.theme.margins.smaller};
  }

  &:hover {
    filter: brightness(125%);
  }

  &:disabled {
    filter: brightness(80%);
    cursor: default;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 480px) {
    font-size: 8px;
  }

  // Variants
  ${(props) => {
    const {
      primary, healthy, warning, danger,
    } = props.theme.color;
    switch (props.variant) {
      case 'primary':
        return css`
          background-color: ${primary};
          border: 1px solid ${primary};
          color: white;
        `;
      case 'secondary':
        return css`
          background-color: white;
          border: 2px solid ${primary};
          color: ${primary};
        `;
      case 'light':
        return css`
          background-color: white;
          border: 2px solid ${primary};
          color: black;
        `;
      case 'danger':
        return css`
          color: white;
          border: 2px solid ${danger};
          background-color: ${danger};
        `;
      case 'warning':
        return css`
          color: white;
          border: 2px solid ${warning};
          background-color: ${warning};
        `;
      case 'healthy':
        return css`
          color: white;
          border: 2px solid ${healthy};
          background-color: ${healthy};
        `;
      case 'link':
        return css`
          color: ${primary};
          background: none;
          text-decoration: underline;
        `;

      default:
        return css`
          background-color: ${primary};
          border: 1px solid ${primary};
          color: white;
        `;
    }
  }};
`;

const Button = (props: ButtonProps) => (
  <ButtonWrapper
    onClick={props.onClick}
    variant={props.variant}
    disabled={props.disabled}
    className={props.className}
  >
    {props.children}
  </ButtonWrapper>
);

export default Button;
